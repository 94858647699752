import React, { useState } from "react";
import "./Auth.css";
import Title from "../../components/Title";
import { updateProfile } from "firebase/auth";
import { auth } from "../../db/firebase";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
  const [sending, setSending] = useState(false);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
//   const [email, setEmail] = useState("");
//   const [phone, setPhone] = useState("");

  const navigate = useNavigate();

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setSending(true);

    await updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: address,
    })
      .then(() => {
        navigate("/useraccount");
        setSending(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="login">
      <div className="login-account container">
        <div className="login-details sendmoney">
          <Title
            title="Mettre à jour le profil"
            // subtitle="To save your details and transactions login."
          />
          <div className="login-form">
            <form onSubmit={HandleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Nom"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {/* <input
                type="text"
                name="phone"
                placeholder="Phone number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              /> */}
              {/* <input
                type="email"
                name="email"
                placeholder="Email Address"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              /> */}
              <input
                type="text"
                name="address"
                placeholder="Adresse physique"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <button type="submit">
                {sending ? "mise à jour..." : "Mise à jour"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(EditProfile);
