import React, { useState, useEffect } from "react";
import "./Auth.css";
import Title from "../../components/Title";
import { auth } from "../../db/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../db/firebase";
import { v4 as uuidv4 } from "uuid";
import { fetchCharges } from "../../utils/data";
import { client } from "../../db/sanity";
import RussiaFlag from "../../assets/RussianFlag.svg";
import USFlag from "../../assets/US.svg";

const SendMoney = () => {
  const [name, setName] = useState("");
  const [card, setCard] = useState("");
  const [phone, setPhone] = useState("");
  const [bank, setBank] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [userID, setUserID] = useState("");
  const [paymentmethod, setPaymentmethod] = useState("");
  const [sending, setSetsending] = useState(false);
  const [other, setOther] = useState("");

  const [selectedOption, setSelectedOption] = useState("US");
  const [processingPercentage, setProcessingPercentage] = useState(0);
  const [firstFee, setFirstFee] = useState(0);
  const [secondFee, setSecondFee] = useState(0);
  const [thirdFee, setThirdFee] = useState(0);
  const [bankingPercent, setBankingPercent] = useState(0);

  const [rubleProcessingFee, setRubleProcessingFee] = useState(0);
  const [rubleBankFee, setRubleBankFee] = useState(0);
  const [processingFeeToShow, setProcessingFeeToShow] = useState(0);

  const [bankingFee, setBankingFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalfee, setTotalfee] = useState(0);
  const [sendingAmount, setSendingAmount] = useState(0);
  const [receivingAmount, setReceivingAmount] = useState(0);
  const [rate, setRate] = useState(0);

  const token = 'AAFmvqeNGaxYu3qXaRP92qLaTXczC4PnEzs';
  const chatId = 6687926845;
  const message = 'Hello, Telegram!';

  useEffect(() => {
    const fetchData = async () => {
      // Fetch Exchange rate
      const BASE_URL =
        "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_COupcdUGQL2PxnNrdAjByG7hPgfNjJ68FHMDThnN";
      await fetch(BASE_URL)
        .then((response) => response.json())
        .then((data) => setRate(data?.data.RUB))
        .catch((error) => {
          console.log(error.message);
        });

      // fetch the charges
      await client.fetch(fetchCharges).then(async (data) => {
        setProcessingPercentage(data[0]?.processingFee);
        setFirstFee(data[0]?.firstFee);
        setSecondFee(data[0]?.secondFee);
        setThirdFee(data[0]?.thirdFee);
        setRubleBankFee(data[0]?.rubleBankFee);
        setRubleProcessingFee(data[0]?.rubleProcessingFee);
        console.log(data[0]);
      });
    };
    fetchData();
  }, []);

  const HandleChange = (e) => {
    setSendingAmount(e.target.value);
    console.log(e.target.value);
  };

  const HandleConvert = async () => {
    // When the currency is in US
    if (selectedOption === "US") {
      // check if sending amount is between 0 and 299
      if (sendingAmount > 0 && sendingAmount <= 299) {
        // set banking percentage
        setBankingPercent(firstFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = firstFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const firstFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * firstFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      }

      // check if sending amount is between 300 and 999
      else if (sendingAmount >= 300 && sendingAmount <= 999) {
        // set banking percentage
        setBankingPercent(secondFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = secondFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const secondFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * secondFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      } else {
        // set banking percentage
        setBankingPercent(thirdFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = thirdFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const secondFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * secondFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      }
    }

    // When the currency is in RUB
    else {
      // set banking fee on sending amount and set it.
      const totalRubleBankFee = rubleBankFee * rate;
      setBankingFee(totalRubleBankFee);
      setProcessingFeeToShow(rubleProcessingFee);

      // calculate processing fee on sending amount and set it.
      const firstFeeCalculated = rubleProcessingFee / 100;
      const processing = sendingAmount - rubleBankFee;
      const processingFeeCalculated = processing * firstFeeCalculated;
      setProcessingFee(processingFeeCalculated);

      // calculate total charge fee
      const totalChargeFee = processingFeeCalculated + totalRubleBankFee;
      setTotalfee(totalChargeFee);

      // convert the dollar to rubles
      const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
      const totalReceivingAmount = totalReceivingAmountCalculated / rate;
      setReceivingAmount(totalReceivingAmount);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setBankingFee(0);
    setProcessingFee(0);
    setTotalfee(0);
    setReceivingAmount(0);
    setSendingAmount(0);
    setProcessingFeeToShow(0);
    setBankingPercent(0);
  };

  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setUserID(user?.uid);
        navigate("/sendmoney");
      } else {
        navigate("/login");
      }
    });
  }, [navigate]);

  const HandleSubmit = async (e) => {
    setSetsending(true);
    e.preventDefault();
    try {
      await addDoc(collection(db, "recipients"), {
        name,
        card,
        phone,
        bank,
        email,
        address,
        SendingAmount: `${
          selectedOption === "US" ? `$${sendingAmount}` : `₽${sendingAmount}`
        }`,
        receivingAmount: `${
          selectedOption === "US"
            ? `₽${receivingAmount}`
            : `$${receivingAmount}`
        }`,
        OtherBank: other,
        PaymentMethod: paymentmethod,
        transactionID: uuidv4().slice(0, 8),
        status: "pending",
        userID: userID,
        createdAt: new Date(),
      }).then(() => {
        navigate("/useraccount");
        setSetsending(false);

        fetch(`https://api.telegram.org/bot${token}/sendMessage`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          chat_id: chatId,
          text: message,
        }),
      })
        .then(response => response.json())
        .then(data => {
          console.log(data);
          // Handle the response data as needed
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle any errors that occurred during the request
        });





      });
    } catch (e) {
      console.error("Error adding document: ", e.message);
    }
  };

  return (
    <div className="login">
      <div className="login-account container">
        <div className="hero-right">
          <h3>Montant d'envoi</h3>
          <div className="row">
            <div className="amount row">
              <input
                type="number"
                placeholder="Enter Amount"
                min="0"
                onChange={(e) => HandleChange(e)}
                value={sendingAmount}
              />
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleOptionChange}
              >
                <option value="US">Dollars</option>
                <option value="RU">Rubles</option>
                <option value="Euro" disabled>Euro</option>             
                <option value="XOF" disabled>Frcfa</option>
              </select>
            </div>
            {selectedOption === "US" ? (
              <img src={USFlag} alt="flag" />
            ) : (
              <img src={RussiaFlag} alt="flag" />
            )}
          </div>
          <div className="fees row">
            <p>
              Frais bancaires
              <span>{selectedOption === "US" && `, ${bankingPercent}%`}</span>
            </p>
            <p>
              {selectedOption === "US" ? "$" : "₽"}
              {bankingFee.toFixed(2)}
            </p>
          </div>
          <div className="fees row">
            <p>Frais de traitement, {processingFeeToShow}%</p>
            <p>
              {selectedOption === "US" ? "$" : "₽"}
              {processingFee.toFixed(2)}
            </p>
          </div>
          <hr />
          <div className="fees row">
            <p>Total des frais:</p>
            <p>
              {selectedOption === "US" ? "$" : "₽"}
              {totalfee.toFixed(2)}
            </p>
          </div>
          <h3>Montant reçu</h3>
          <div className="amount row">
            <p className="receiving">
              {selectedOption === "US" ? "₽" : "$"} {receivingAmount.toFixed(2)}
            </p>
            <div className="flag row">
              <p>{selectedOption === "US" ? "Rubles" : "Dollars"}</p>
              {selectedOption === "US" ? (
                <img src={RussiaFlag} alt="flag" />
              ) : (
                <img src={USFlag} alt="flag" />
              )}
            </div>
          </div>
          <div className="fees row">
            <p>Taux de change actuel:</p>
            <p>$1 = ₽{rate.toFixed(2)}</p>
          </div>
          <span className="convert" onClick={() => HandleConvert()}>
            CONVERTIR
          </span>
          {/* <NavLink className="get_started" to="/sendmoney">
            GET STARTED NOW
          </NavLink> */}
          {paymentmethod === "Bank Deposit" && (
            <div className="bank-details">
              <h3>Bank Details</h3>
              <p>Recipient name : LOUIS GESSIE</p>
              <p>Account number: 1800 0028 297</p>
              <p>Banque : Banque de l’Union Haitienne (BUH)</p>
              <p>Currency : USD $</p>
            </div>
          )}
          {(paymentmethod === "Bank Transfer" ||
            paymentmethod === "MoneyGram Ria" ||
            paymentmethod === "Western Union") && (
            <div className="bank-details">
              <h3>Bank Details</h3>
              <p>Recipient name : LOUIS GESSIE</p>
              <p>Account number: 1800 0028 297</p>
              <p>Banque : Banque de l’Union Haitienne (BUH)</p>
              <p>Currency : USD $</p>
              <p>Email: louisgessie@gmail.com</p>
              <p>Telephone : +509 3147-0455</p>
              <p>Adress: 13, Puits-Blain 18, Port-au-Prince, Haiti</p>
            </div>
          )}
         
        </div>
        <div className="login-details sendmoney">
          <Title
            title="Détails du destinataire"
            // subtitle="To save your details and transactions login."
          />
          <div className="login-form">
            <form onSubmit={HandleSubmit}>
              <input
                type="text"
                name="name"
                placeholder="Nom du destinataire"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                type="text"
                minlength="16"
                maxlength="16"
                name="card"
                placeholder="Entrez un numéro de carte à 16 chiffres"
                required
                value={card}
                onChange={(e) => setCard(e.target.value)}
              />
              <input
                type="text"
                name="phone"
                placeholder="Numéro de téléphone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <div className="options-forms">
                <select
                  id="dropdown"
                  value={bank}
                  onChange={(e) => setBank(e.target.value)}
                >
                  <option value="">Choisissez la banque</option>
                  <option value="Tinkoff">Tinkoff</option>
                  <option value="Sberbank">Sberbank</option>
                  <option value="other">Autre</option>
                </select>
                {bank === "other" && (
                  <input
                    type="text"
                    name="bank"
                    placeholder="Nom de banque"
                    required
                    value={other}
                    onChange={(e) => setOther(e.target.value)}
                  />
                )}
                <select
                  id="dropdown"
                  value={paymentmethod}
                  onChange={(e) => setPaymentmethod(e.target.value)}
                  required
                >
                  <option value="">Choisissez le mode de paiement</option>
                  <option value="Bank Deposit">Dépôt bancaire</option>
                  <option value="Bank Transfer">Virement</option>
                  <option value="MoneyGram Ria">MoneyGram, Ria</option>
                  <option value="Western Union">Western Union</option>
                </select>
              </div>
              <input
                type="email"
                name="email"
                placeholder="Adresse e-mail (facultatif)"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="text"
                name="address"
                placeholder="Adresse physique (facultatif)"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <button type="submit">
                {sending ? "Envoi en cours..." : "Envoyer les détails"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(SendMoney);
