import React, { useState, useEffect } from "react";
import "./TeamSection.css";
import Title from "../Title";
// import { NavLink } from "react-router-dom";
import { client } from "../../db/sanity";
import { fetchSteps } from "../../utils/data";
// import Profile from "../../assets/profile.jpg";

const TeamSection = () => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchSteps).then((data) => {
        setSteps(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="teamsection">
      {/* <div className="team-layer"></div> */}
      <div className="container top-layer">
        <div className="team-title">
          <Title
            subtitle={
              "Etapes pour envoyer de l'argent"
            }
          />
        </div>
        <div className="learn-more">
          {console.log(steps)}
          <div className="container">
            <div className="steps container">
              {steps?.map((step) => (
                <div
                  key={step?._id}
                  className="step layout-col-lg-md-4 layout-col-lg-4 layout-col-md-6 layout-col-sm-12"
                >
                  <h1>{step?.number}</h1>
                  <h4>{step?.title}</h4>
                  <p>{step?.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamSection;
