import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import Home from "./container/Home/Home";
import Blog from "./container/Blog/Blog";
import BlogDetails from "./container/Blog/BlogDetails";
import ContactUs from "./container/ContactUs/ContactUs";
import Team from "./container/Team/Team";
import AboutUs from "./container/AboutUs/AboutUs";
import Login from "./container/Auth/Login";
import Register from "./container/Auth/Register";
import UserAccount from "./container/Auth/UserAccount";
import SendMoney from "./container/Auth/SendMoney";
import EditProfile from "./container/Auth/EditProfile";
import LearnMore from "./container/Auth/LearnMore";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/Register" element={<Register />} />
        <Route exact path="/sendmoney" element={<SendMoney />} />
        <Route exact path="/learnmore" element={<LearnMore />} />
        <Route exact path="/editprofile" element={<EditProfile />} />
        <Route exact path="/useraccount" element={<UserAccount />} />
        <Route exact path="/contactus" element={<ContactUs />} />
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/blog/:slug" element={<BlogDetails />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
