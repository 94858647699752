import React, { useState, useEffect } from "react";
import "./BlogSection.css";
import Title from "../Title";
import RightArrow from "../../assets/RightArrow.svg";
import { NavLink } from "react-router-dom";
import { client, urlFor } from "../../db/sanity";
import { fetchPosts } from "../../utils/data";

const BlogSection = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchPosts).then((data) => {
        setPosts(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="blogsection">
      <div className="container">
        <Title
          title={"Notre blog"}
          subtitle={"Lisez nos derniers articles de blog sur le transfert d'argent et plus encore !"}
        />
        <div className="posts">
          {posts?.map((post) => (
            <div className="post-item layout-col-lg-4 layout-6 layout-col-sm-12" key={post?._id}>
              <div
                className="post-image"
                style={{ backgroundImage: `url(${urlFor(post?.coverImage)})` }}
              ></div>
              <div className="post-content">
                <h3>{post?.title.slice(0, 70)}...</h3>
                <p>{post?.description.slice(0, 250)}...</p>
                <div className="read-more">
                  <NavLink to={`/blog/${post?.slug.current}`}>Read More</NavLink>
                  <img src={RightArrow} alt="right arrow" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="view-all">
          <NavLink to={"/blog"}>Afficher tous les messages</NavLink>
          <img src={RightArrow} alt="right arrow" />
        </div>
      </div>
    </div>
  );
};

export default BlogSection;
