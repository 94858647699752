import React, { useState, useEffect } from "react";
import "./Team.css";
import Title from "../../components/Title";
import { client } from "../../db/sanity";
import { fetchTeam } from "../../utils/data";

const Team = () => {
  const [team, setTeam] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchTeam).then((data) => {
        setTeam(data);
        console.log(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="team">
      <Title title="À propos de notre équipe" subtitle="Rencontrez les membres de notre équipe!" />
      <div className="container about-container">
        <div className="team-members">
          {team?.map((member) => (
            <div className="member">
              <div
                className="member-image"
                style={{
                  backgroundImage: `url(${member?.profileImage.asset.url})`,
                }}
              ></div>
              <div className="team-info">
                <h3>{member?.name}</h3>
                <h4>{member?.occupation}</h4>
                <h5>{member?.address}</h5>
                <p>{member?.about}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Team);
