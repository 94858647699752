import React from "react";
import "./styles.css";

const Title = ({ title, subtitle }) => {
  return (
    <div className="title">
      <div className="review-heading">
          <h4>{title}</h4>
          <h3>{subtitle}</h3>
        </div>
    </div>
  );
};

export default Title;