import React, { useState, useEffect } from "react";
import "./HeroSection.css";
import RussiaFlag from "../../assets/RussianFlag.svg";
import USFlag from "../../assets/US.svg";
import { client } from "../../db/sanity";
import { fetchCharges } from "../../utils/data";
import { NavLink } from "react-router-dom";
// import CurrencyAPI from "@everapi/currencyapi-js";
// import axios from "axios";

const HeroSection = () => {
  const [selectedOption, setSelectedOption] = useState("US");
  const [processingPercentage, setProcessingPercentage] = useState(0);
  const [firstFee, setFirstFee] = useState(0);
  const [secondFee, setSecondFee] = useState(0);
  const [thirdFee, setThirdFee] = useState(0);
  const [bankingPercent, setBankingPercent] = useState(0);

  const [rubleProcessingFee, setRubleProcessingFee] = useState(0);
  const [rubleBankFee, setRubleBankFee] = useState(0);
  const [processingFeeToShow, setProcessingFeeToShow] = useState(0);

  const [bankingFee, setBankingFee] = useState(0);
  const [processingFee, setProcessingFee] = useState(0);
  const [totalfee, setTotalfee] = useState(0);
  const [sendingAmount, setSendingAmount] = useState(0);
  const [receivingAmount, setReceivingAmount] = useState(0);
  const [rate, setRate] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      // Fetch Exchange rate
      // const BASE_URL =
      //   "https://api.freecurrencyapi.com/v1/latest?apikey=fca_live_COupcdUGQL2PxnNrdAjByG7hPgfNjJ68FHMDThnN";
      // await fetch(BASE_URL)
      //   .then((response) => response.json())
      //   .then((data) => setRate(data?.data.RUB))
      //   .catch((error) => {
      //     console.log(error.message);
      //   });

      // fetch the charges
      await client.fetch(fetchCharges).then(async (data) => {
        setRate(data[0]?.rate);
        setProcessingPercentage(data[0]?.processingFee);
        setFirstFee(data[0]?.firstFee);
        setSecondFee(data[0]?.secondFee);
        setThirdFee(data[0]?.thirdFee);
        setRubleBankFee(data[0]?.rubleBankFee);
        setRubleProcessingFee(data[0]?.rubleProcessingFee);
        console.log(data[0]);
      });
    };
    fetchData();
  }, []);

  const HandleChange = (e) => {
    setSendingAmount(e.target.value);
    console.log(e.target.value);
  };

  const HandleConvert = async () => {
    // When the currency is in US
    if (selectedOption === "US") {
      // check if sending amount is between 0 and 299
      if (sendingAmount > 0 && sendingAmount <= 299) {
        // set banking percentage
        setBankingPercent(firstFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = firstFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const firstFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * firstFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      }

      // check if sending amount is between 300 and 999
      else if (sendingAmount >= 300 && sendingAmount <= 999) {
        // set banking percentage
        setBankingPercent(secondFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = secondFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const secondFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * secondFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      } else {
        // set banking percentage
        setBankingPercent(thirdFee);
        setProcessingFeeToShow(processingPercentage);

        // calculate banking fee on sending amount and set it.
        const bankingPercentCalculated = thirdFee / 100;
        const bankingFeeCalculated = sendingAmount * bankingPercentCalculated;
        setBankingFee(bankingFeeCalculated);

        // calculate processing fee on sending amount and set it.
        const secondFeeCalculated = processingPercentage / 100;
        const processing = sendingAmount - bankingFeeCalculated;
        const processingFeeCalculated = processing * secondFeeCalculated;
        setProcessingFee(processingFeeCalculated);

        // calculate total charge fee
        const totalChargeFee = processingFeeCalculated + bankingFeeCalculated;
        setTotalfee(totalChargeFee);

        // convert the dollar to rubles
        const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
        const totalReceivingAmount = totalReceivingAmountCalculated * rate;
        setReceivingAmount(totalReceivingAmount);
      }
    }

    // When the currency is in RUB
    else {
      // set banking fee on sending amount and set it.
      const totalRubleBankFee = rubleBankFee * rate;
      setBankingFee(totalRubleBankFee);
      setProcessingFeeToShow(rubleProcessingFee);

      // calculate processing fee on sending amount and set it.
      const firstFeeCalculated = rubleProcessingFee / 100;
      const processing = sendingAmount - rubleBankFee;
      const processingFeeCalculated = processing * firstFeeCalculated;
      setProcessingFee(processingFeeCalculated);

      // calculate total charge fee
      const totalChargeFee = processingFeeCalculated + totalRubleBankFee;
      setTotalfee(totalChargeFee);

      // convert the dollar to rubles
      const totalReceivingAmountCalculated = sendingAmount - totalChargeFee;
      const totalReceivingAmount = totalReceivingAmountCalculated / rate;
      setReceivingAmount(totalReceivingAmount);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setBankingFee(0);
    setProcessingFee(0);
    setTotalfee(0);
    setReceivingAmount(0);
    setSendingAmount(0);
    setProcessingFeeToShow(0);
    setBankingPercent(0);
  };

  return (
    <div className="hero">
      <div className="container">
        <div className="row flex-hero">
          <div className="hero-left">
            <h1>
              Envoyez de l'argent à <span>l'étranger!</span>
            </h1>
            <p>
              Effectuez des transferts d'argent entre Haïti et la Russie. Avec
              “well transfer” facilitez vos transactions financières
              internationales en toute fiabilité et sécurité.
            </p>
          </div>
          <div className="hero-right">
            <h3>Montant d'envoi</h3>
            <div className="row">
              <div className="amount row">
                <input
                  type="number"
                  placeholder="Enter Amount"
                  min="0"
                  onChange={(e) => HandleChange(e)}
                  value={sendingAmount}
                />
                <select
                  id="dropdown"
                  value={selectedOption}
                  onChange={handleOptionChange}
                >
                  <option value="US">Dollar</option>
                  <option value="RU">Rouble</option>
                  <option value="EUR" disabled>Euro</option>             
                  <option value="Frcfa" disabled>XOF</option>
                  </select>                
              </div>
              {selectedOption === "US" ? (
                <img src={USFlag} alt="flag" />
              ) : (
                <img src={RussiaFlag} alt="flag" />
              )}
            </div>
            <div className="fees row">
              <p>
                Frais bancaires
                <span>{selectedOption === "US" && `, ${bankingPercent}%`}</span>
              </p>
              <p>
                {selectedOption === "US" ? "$" : "₽"}
                {bankingFee.toFixed(2)}
              </p>
            </div>
            <div className="fees row">
              <p>Frais de traitement, {processingFeeToShow}%</p>
              <p>
                {selectedOption === "US" ? "$" : "₽"}
                {processingFee.toFixed(2)}
              </p>
            </div>
            <hr />
            <div className="fees row">
              <p>Total des frais:</p>
              <p>
                {selectedOption === "US" ? "$" : "₽"}
                {totalfee.toFixed(2)}
              </p>
            </div>
            <h3>Montant reçu</h3>
            <div className="amount row">
              <p className="receiving">
                {selectedOption === "US" ? "₽" : "$"}{" "}
                {receivingAmount.toFixed(2)}
              </p>
              {/* <input type="number" placeholder="amount" /> */}
              <div className="flag row">
                <p>{selectedOption === "US" ? "Rouble" : "Dollar"}</p>
                {selectedOption === "US" ? (
                  <img src={RussiaFlag} alt="flag" />
                ) : (
                  <img src={USFlag} alt="flag" />
                )}
              </div>
            </div>
            <div className="fees row">
              <p>Taux de change actuel:</p>
              <p>$1 = ₽{rate.toFixed(2)}</p>
            </div>
            <span className="convert" onClick={() => HandleConvert()}>
              CONVERTIR
            </span>
            <NavLink className="get_started" to="/sendmoney">
              COMMENCEZ MAINTENANT
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
