import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { auth } from "../../db/firebase";
import { onAuthStateChanged } from "firebase/auth";
import Well from "../../assets/well.png";

const Navbar = () => {
  const [showNav, setShowNav] = useState(false);
  const [showLogin, setShowLogin] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setShowLogin(false);
      } else {
        setShowLogin(true);
      }
    });
  }, [navigate]);

  const handleShowNavbar = () => {
    setShowNav(!showNav);
  };

  return (
    <nav className="navbar">
      <div className="nav-container">
        <NavLink to="/">
          <div className="logo">
            <img src={Well} alt="Well Icon" />
            <div>
              <h1>WELL TRANSFER </h1>
              <p>Fast Secure Affordable</p>
            </div>
          </div>
        </NavLink>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <HiOutlineMenuAlt3 />
        </div>
        <div className={`nav-elements  ${showNav && "active"}`}>
          <ul>
            <div className="cross" onClick={handleShowNavbar}>
              <RxCross2 />
            </div>
            <li>
              <NavLink to="/">Accueil</NavLink>
            </li>
            <li>
              <NavLink to="/aboutus">À propos de nous</NavLink>
            </li>

            <li>
              <NavLink to="/team">Équipe</NavLink>
            </li>
            <li>
              <NavLink to="/contactus">Contactez-nous</NavLink>
            </li>
            {showLogin === null ? (
              " "
            ) : (
              <>
                {showLogin ? (
                  <>
                    <li>
                      <NavLink to="/login">Connexion</NavLink>
                    </li>
                    <li>
                      <NavLink className="getstarted" to="/register">
                        Créer un compte
                      </NavLink>
                    </li>
                  </>
                ) : (
                  <li>
                    <NavLink to="/useraccount">Tableau de bord</NavLink>
                  </li>
                )}
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
