import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC5L2pjA-x2LWn2VbfhSz0n1a6x76xA7c4",
  authDomain: "well-transfer.firebaseapp.com",
  projectId: "well-transfer",
  storageBucket: "well-transfer.appspot.com",
  messagingSenderId: "668735662337",
  appId: "1:668735662337:web:0a4feb777dafcaca4a1300",
  measurementId: "G-FM9L122HGV"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 
export const googleProvider = new GoogleAuthProvider();