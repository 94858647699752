import React from "react";
import "./AboutUs.css";
import Title from "../../components/Title";
import AboutImage from "../../assets/whoweare.png";
import Values from "../../components/Values/Values";

const AboutUs = () => {
  return (
    <div className="aboutus">
      <div className="container">
        <Title
          title="Qui nous sommes"
          subtitle="Well Transfer est un fournisseur de services de paiement de premier plan qui aide les particuliers à envoyer facilement de l'argent en Russie."
        />
      </div>
      <div className="aboutImage">
        <img src={AboutImage} alt="who we are" />
      </div>
      <div className="container">
        <div className="mission-vision">
          <h1>Mission</h1>
          <p>
            Chez Well Transfer, notre mission est de simplifier et d'améliorer
            les transferts d'argent internationaux, en mettant l'accent sur les
            transferts entre Haïti et la Russie. Notre objectif principal est
            d'aider les familles et les amis en Haïti à envoyer de l'argent à
            leurs proches qui étudient en Russie, tout en permettant aux
            étudiants ici de les recevoir rapidement, facilement et à moindre
            coût.
          </p>
        </div>
        <div className="mission-vision">
          <h1>Vision</h1>
          <p>
            Notre vision est de révolutionner la façon dont les gens transfèrent
            de l'argent entre Haïti et la Russie, et ce, à l'échelle mondiale.
            Nous aspirons à devenir la plateforme de référence pour les
            particuliers à la recherche de solutions de transfert d'argent
            international rapides, transparentes et rentables entre ces deux
            pays. En tirant parti de la technologie innovante et d'une approche
            centrée sur le client, nous visons à créer un réseau mondial qui
            connecte de manière transparente les personnes, les entreprises et
            les opportunités, en facilitant les transferts d'argent entre Haïti
            et la Russie ainsi que dans le reste du monde.
          </p>
        </div>
      </div>
      <Values />
    </div>
  );
};

export default React.memo(AboutUs);
