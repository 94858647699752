import React from "react";
import "./ContactUs.css";
import Title from "../../components/Title";
import GetInTouch from "../../assets/GetInTouchImage.png";
import { MdAttachEmail } from "react-icons/md";
import { FaPhoneVolume } from "react-icons/fa";
import { ImLocation2 } from "react-icons/im";

const ContactUs = () => {
  return (
    <div className="contactus">
      <div className="container">
        <Title
          title="Entrer en contact"
          subtitle="Contactez-nous pour obtenir de l'aide et des demandes de renseignements"
        />
      </div>
      <div className="aboutImage">
        <img src={GetInTouch} alt="Contact Us" />
      </div>
      <div className="container">
        <div className="get-in-touch">
          <div className="contact-details">
            <div className="contact-item">
              <h3>
                <MdAttachEmail />
                <span>E-mail</span>
              </h3>
              <p>
                <a href="mailto:Welltransfer@outlook.com">
                  Welltransfer@outlook.com
                </a>
              </p>
            </div>
            <div className="contact-item">
              <h3>
                <FaPhoneVolume />
                <span>Téléphone</span>
              </h3>
              <p>
                <a href="tel:+79319854706">+7 (931) 985-47-06</a>
              </p>
            </div>
            <div className="contact-item">
              <h3>
                <ImLocation2 />
                <span>Adresse physique</span>
              </h3>
              <p>Saint-Pétersbourg, Russie</p>
            </div>
          </div>
          <div className="contact-form">
            <h1>Envoie-nous un message</h1>
            <form action="https://formsubmit.co/19d0979cb330e73147c2c71d7d891b73" method="POST">
              <input
                type="email"
                name="email"
                placeholder="Adresse e-mail"
                required
              />
              <input
                type="text"
                name="Full names"
                placeholder="Noms complets"
                required
              />
              <input type="hidden" name="_template" value="table"></input>
              <textarea type="text" name="Message" placeholder="Message" />
              <button type="submit">Envoyer le message</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContactUs);
