export const fetchCharges = `*[_type == "Charges" ] | order(_createdAt desc) {
      rate,
      firstFee,
      secondFee,
      thirdFee,
      processingFee,
      rubleBankFee,
      rubleProcessingFee
}`;

export const fetchBlog = `*[_type == "Blog" ] | order(_createdAt desc) {
      title,
      subtitle,
      description,
      _id,
      slug,
      coverImage {
            asset->{
              url
            }
          },
      categories[]-> {
            title,
      },
      date,
      content
}`;

export const fetchPosts = `*[_type == "Blog" ] | order(_createdAt desc)[0...3] {
      title,
      subtitle,
      description,
      _id,
      slug,
      coverImage {
            asset->{
              url
            }
          },
      categories[]-> {
            title,
      },
      date,
      content
}`;

export const fetchPostDetail = (slug) => {
  const query = `*[_type == "Blog" && slug.current == '${slug}'] {
            title,
            subtitle,
            description,
            _id,
            slug,
            coverImage {
                  asset->{
                    url
                  }
                },
            categories[]-> {
                  title,
            },
            date,
            content
              }`;
  return query;
};

export const fetchReviews = `*[_type == "Reviews" ] | order(_createdAt desc) {
      title,
      name,
      _id,
      review,
      profileImage {
            asset->{
              url
            }
          },
}`;

export const fetchProof = `*[_type == "Proof" ] | order(_createdAt desc) {
      transactionID,
      "file": file.asset->,
      "deposit": deposit.asset->
}`;

export const fetchTeam = `*[_type == "Team" ] | order(_createdAt desc) {
      name,
      about,
      occupation,
      address,
      _id,
      profileImage {
            asset->{
              url
            }
          },
}`;

export const fetchQuestions = `*[_type == "Questions" ] | order(_createdAt desc) {
      question,
      answer,
      _id,
}`;

export const fetchSteps = `*[_type == "Steps" ] | order(_createdAt desc) {
      title,
      text,
      number,
      _id,
}`;

export const searchQuery = (searchTerm) => {
  const search = `*[_type == "Blog" && title match '*${searchTerm}*' || category->title match '*${searchTerm}*' ] | order(_createdAt asc) {
            title,
            subtitle,
            description,
            _id,
            slug,
            coverImage {
                  asset->{
                    url
                  }
                },
            categories[]-> {
                  title,
            },
            date,
            content
      }`;
  return search;
};
