import React from "react";
import "./Review.css";
import Quote from "../../assets/Quote.svg";
// import { urlFor } from "../../db/sanity";

const Card = ({ review }) => {
  return (
    <div className="card">
      {console.log(review?.profileImage)}
      <div
        className="card-image"
        style={{
          backgroundImage: `url(${
            review?.profileImage === null ? "" : review?.profileImage.asset.url
          })`,
        }}
      >
        <img className="quote" src={Quote} alt="quote" />
      </div>
      <div className="card-content">
        <p>{review?.review}</p>
        <h3>{review?.name}</h3>
        <h4>{review?.title}</h4>
      </div>
    </div>
  );
};

export default Card;
