import React, { useState, useEffect } from "react";
import "./Blog.css";
import { client, urlFor } from "../../db/sanity";
import { fetchBlog, searchQuery } from "../../utils/data";
import Title from "../../components/Title";
import { NavLink } from "react-router-dom";
import RightArrow from "../../assets/RightArrow.svg";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchBlog).then((data) => {
        setPosts(data);
      });
    };
    fetchData();
  }, []);

  const HandleChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
    if (searchText === "") {
      client.fetch(fetchBlog).then((data) => {
        setPosts(data);
      });
    } else {
      client.fetch(searchQuery(e.target.value)).then((data) => {
        setPosts(data);
      });
    }
  };

  return (
    <div className="blog">
      {console.log(searchText)}
      <div className="container">
        <Title title={"Notre blog"} subtitle={"Les écrits de notre équipe!"} />
        <div className="search">
          <input
            type="text"
            placeholder="Search articles..."
            onChange={(e) => HandleChange(e)}
            value={searchText}
          />
        </div>
        <div className="posts">
          {posts?.map((post) => (
            <div
              className="post-item layout-col-lg-4 layout-6 layout-col-sm-12"
              key={post?._id}
            >
              <div
                className="post-image"
                style={{ backgroundImage: `url(${urlFor(post?.coverImage)})` }}
              ></div>
              <div className="post-content">
                <h3>{post?.title.slice(0, 70)}...</h3>
                <p>{post?.description.slice(0, 250)}...</p>
                <div className="read-more">
                  <NavLink to={`/blog/${post?.slug.current}`}>
                    Read More
                  </NavLink>
                  <img src={RightArrow} alt="right arrow" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Blog);
