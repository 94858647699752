import React from "react";
import "./AboutSection.css";
import AboutImage from "../../assets/AboutUsSideImage.png";
import { NavLink } from "react-router-dom";

const AboutSection = () => {
  return (
    <div className="aboutsection">
      <div className="container">
        <div className="row flex-about">
          <div className="about-left">
            <h4>À propos de nous</h4>
            <h2>Rapide, securisee, Abordable.</h2>
            <p>
              Well Transfer est un fournisseur de services de paiement dédié à
              aider les particuliers à envoyer de l'argent à l'étranger en toute
              simplicité. Nous nous spécialisons dans la facilitation de
              transactions internationales sécurisées et efficaces, en mettant
              l'accent sur les besoins spécifiques des étudiants vivant et
              étudiant en Russie. Avec notre plateforme accueillante, transférer
              de l'argent depuis Haïti, les Etats-Unis, la République
              Dominicaine ou l'Europe vers la Russie n'a jamais été aussi rapide
              et pratique.
            </p>
            <NavLink to={"/aboutus"}>Apprendre encore plus</NavLink>
          </div>
          <div className="about-right">
            <img src={AboutImage} alt="about us" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
