import React, { useState, useEffect } from "react";
import "./Questions.css";
import Title from "../Title";
import DownArrow from "../../assets/DownArrow.svg";
import UpArrow from "../../assets/UpArrow.svg";
import { client } from "../../db/sanity";
import { fetchQuestions } from "../../utils/data";

const Questions = () => {
  const [isopen, setIsopen] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [openQuestion, setOpenQuestion] = useState(null);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchQuestions).then((data) => {
        setQuestions(data);
      });
    };
    fetchData();
  }, []);

  const HandleClick = (index) => {
    setIsopen(!isopen);
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  return (
    <div className="questions">
      <div className="container">
        <Title
          title={"Questions fréquemment posées"}
        />
        <div className="question-list">
          {questions?.map((question, index) => (
            <div className="question-item" key={question?._id}>
              <div className="top-item">
                <h3>{question?.question}</h3>
                <img
                  src={openQuestion === index ? UpArrow : DownArrow}
                  alt="DownArrow"
                  onClick={() => HandleClick(index)}
                />
              </div>
              {openQuestion === index && <p>{question?.answer}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Questions;
