import React from "react";
import "./Home.css";
import HeroSection from "../../components/HeroSection/HeroSection";
import AboutSection from "../../components/AboutSection/AboutSection";
import Review from "../../components/Review/Review";
import Values from "../../components/Values/Values";
import BlogSection from "../../components/BlogSection/BlogSection";
import Questions from "../../components/Questions/Questions";
import TeamSection from "../../components/TeamSection/TeamSection";

const Home = () => {
  return (
    <div className="home">
      <HeroSection />
      <AboutSection />
      <Review />
      <Values />
      <TeamSection />
      <BlogSection />
      <Questions />
    </div>
  );
};

export default React.memo(Home);
