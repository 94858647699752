import React from "react";
import "./Values.css";
import Reliability from "../../assets/Reliability.svg";
import Simplicity from "../../assets/Simplicity.svg";
import Transparency from "../../assets/Transparency.svg";
import Speed from "../../assets/Speed.svg";
import Focus from "../../assets/Focus.svg";
import Title from "../Title";

const Values = () => {
  return (
    <div className="values">
      <div className="container">
        <Title
          title={"Nos valeurs"}
        />
        <div className="value-items">
          <div className="value-item layout-col-lg-4 layout-col-lg-md-6 layout-col-sm-12">
            <img src={Reliability} alt="Reliability" />
            <h5>Fiabilité</h5>
            <p>
              Nous accordons la priorité à la sécurité et à la fiabilité de
              chaque transaction, veillant à ce que les fonds parviennent
              rapidement et en toute sécurité à leurs destinataires prévus.
            </p>
          </div>
          <div className="value-item layout-col-lg-4 layout-col-lg-md-6 layout-col-sm-12">
            <img src={Simplicity} alt="Simplicity" />
            <h5>Simplicité</h5>
            <p>
              Nous nous efforçons de rendre le processus de transfert d'argent
              aussi simple et convivial que possible, en éliminant les
              complexités et les obstacles inutiles.
            </p>
          </div>
          <div className="value-item layout-col-lg-4 layout-col-lg-md-6 layout-col-sm-12">
            <img src={Transparency} alt="Transparency" />
            <h5>Transparence</h5>
            <p>
              Nous croyons en une tarification transparente, fournissant des
              informations claires et préalables sur les frais, les taux de
              change et tout autre frais pertinent.
            </p>
          </div>
          <div className="value-item layout-col-lg-4 layout-col-lg-md-6 layout-col-sm-12">
            <img src={Speed} alt="Speed" />
            <h5>Rapidité</h5>
            <p>
              Nous accordons la priorité au transfert rapide et efficace des
              fonds, comprenant l'importance des transactions en temps opportun
              pour nos clients.
            </p>
          </div>
          <div className="value-item layout-col-lg-4 layout-col-lg-md-6 layout-col-sm-12">
            <img src={Focus} alt="Focus" />
            <h5>Orientation client</h5>
            <p>
              Les besoins et la satisfaction de nos clients sont au cœur de
              toutes nos actions. Nous nous engageons à offrir un service
              exceptionnel et un support à chaque étape du parcours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Values;
