import React from "react";
import Load from "../assets/Loading.gif";
import "./styles.css";

export const Loading = () => {
  return (
    <div className="loadImage">
      <img src={Load} alt="load_image" />
    </div>
  );
};

export default React.memo(Loading);