import React, { useState, useEffect } from "react";
import "./Auth.css";
import { onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { auth } from "../../db/firebase";
import { NavLink, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import { db } from "../../db/firebase";
import { client } from "../../db/sanity";
import { GiCancel } from "react-icons/gi";
import { BsFillFileEarmarkArrowDownFill } from "react-icons/bs";
import Loading from "../../components/Loading";
import { fetchProof } from "../../utils/data";

const UserAccount = () => {
  const [user, setUser] = useState({});
  const [recipients, setRecipients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isSending, setIsSending] = useState(null);
  const [removeTag, setRemoveTag] = useState(false);
  const [userloading, setUserLoading] = useState(true);
  const [proof, setProof] = useState([]);

  const navigate = useNavigate();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const checkUser = async () => {
      await onAuthStateChanged(auth, async (user) => {
        if (user) {
          setUserLoading(false);
          setUser(user);
          client.fetch(fetchProof).then((data) => {
            setProof(data);
            console.log(data);
          });
          const q = query(
            collection(db, "recipients"),
            where("userID", "==", `${user?.uid}`),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(q);
          const filteredData = querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
          setRecipients(filteredData);
          setLoading(false);
        } else {
          navigate("/login");
        }
      });
    };
    checkUser();
  }, [navigate]);

  const HandleSignOut = () => {
    signOut(auth)
      .then(() => {
        navigate("/login");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const HandleFileUpload = async (e, index, transactionID, sanityID) => {
    e.preventDefault();
    // console.log(transactionID);
    if (isSending === index) {
      setIsSending(null);
    } else {
      setIsSending(index);
    }

    // upload file to sanity
    const uploadOptions = {
      filename: selectedFile.name,
      contentType: selectedFile.type,
    };

    client.assets
      .upload("file", selectedFile, uploadOptions)
      .then((result) => {
        const documentData = {
          _type: "Proof",
          transactionID: sanityID,
          file: {
            _type: "file",
            asset: {
              _type: "reference",
              _ref: result._id,
            },
          },
        };
        return client.create(documentData);
      })
      .then(async (response) => {
        // changing the status of the recipients
        const recipientRef = doc(db, "recipients", `${transactionID}`);
        await updateDoc(recipientRef, {
          status: "sending",
        });

        // update the recipients
        const updatedRecipients = recipients.map((recipient) => {
          if (recipient.id === transactionID) {
            return { ...recipient, status: "sending" };
          }
          return recipient;
        });
        setRecipients(updatedRecipients);
        setRemoveTag(true);
        setTimeout(() => {
          setRemoveTag(false);
        }, 60000);
        console.log("Document created successfully:", response);
      })
      .catch((error) => {
        console.error("Error creating document:", error.message);
      });
  };

  return (
    <div>
      {userloading ? (
        <Loading />
      ) : (
        <div className="useraccount">
          <div className="container">
            <div className="top-section">
              <div className="logout">
                <p onClick={HandleSignOut}>
                  Se déconnecter <MdLogout />
                </p>
              </div>
              <div className="profile-image"></div>
            </div>
            <div className="account">
              <div className="account-details">
                <h1>{user?.displayName}</h1>
                <h3>{user?.email}</h3>
                {/* <p>{user?.photoURL}</p> */}
                <p>{user?.phoneNumber}</p>
                <div className="operations">
                  <NavLink to="/editprofile">
                    <button className="edit">Editer le profil</button>
                  </NavLink>
                  <NavLink className="btn-send" to="/sendmoney">
                    <button className="send">Envoyer de l'argent</button>
                  </NavLink>
                </div>
                <p>
                  Vous pouvez facilement envoyer de l'argent avec nous et suivre
                  l'état de votre Historique des transactions du compte.
                </p>
                <p>
                  Comment?{" "}
                  <NavLink to="/learnmore">Apprendre encore plus</NavLink>
                </p>
              </div>
              <div className="history">
                <h3>Historique des transactions</h3>
                {loading ? (
                  <p>Chargement des transactions...</p>
                ) : (
                  <>
                    {recipients.length === 0 ? (
                      <p>Vous n'avez encore effectué aucune transaction.</p>
                    ) : (
                      <>
                        {recipients?.map((recipient, index) => (
                          <div className="recipient-details" key={index}>
                            <div className="right-details">
                              <p>
                                <span>Nom du destinataire:</span>{" "}
                                {recipient?.name}
                              </p>
                              <p>
                                <span>Numéro de carte:</span> {recipient?.card}
                              </p>
                              <p>
                                <span>Numéro de téléphone:</span>{" "}
                                {recipient?.phone}
                              </p>
                              <p>
                                <span>Nom de banque:</span> {recipient?.bank}
                              </p>
                              <p>
                                <span>Other Bank:</span> {recipient?.OtherBank}
                              </p>
                            </div>
                            <div className="left-details">
                              <p>
                                <span>identifiant de transaction:</span>{" "}
                                {recipient?.transactionID}
                              </p>
                              <p>
                                <span>Adresse e-mail:</span> {recipient?.email}
                              </p>
                              <p>
                                <span>Adresse physique:</span>{" "}
                                {recipient?.address}
                              </p>
                              <p
                                style={{
                                  color:
                                    recipient?.status === "pending"
                                      ? "#dfb844"
                                      : recipient?.status === "sending"
                                      ? "#0d67b5"
                                      : recipient?.status === "done"
                                      ? "#27AE60"
                                      : "",
                                  fontWeight: "600",
                                }}
                              >
                                <span>Statut:</span>{" "}
                                {recipient?.status === "pending" &&
                                  "En attente"}
                                {recipient?.status === "done" &&
                                  "Envoi réussi"}
                                {recipient?.status === "sending" &&
                                  "Transaction en cours"}
                              </p>
                              {recipient?.status === "pending" ? (
                                <div className="upload-file">
                                  <p>Télécharger une preuve de dépôt</p>
                                  <form
                                    onSubmit={(e) =>
                                      HandleFileUpload(
                                        e,
                                        index,
                                        recipient?.id,
                                        recipient?.transactionID
                                      )
                                    }
                                  >
                                    <input
                                      type="file"
                                      onChange={handleFileChange}
                                      accept="image/*,.pdf"
                                      required
                                    />
                                    <button type="submit">
                                      {isSending === index
                                        ? "Téléchargement..."
                                        : "Télécharger"}
                                    </button>
                                  </form>
                                </div>
                              ) : (
                                ""
                              )}
                              {recipient?.status === "done" && (
                                <>
                                  {proof?.map((proof, index) => (
                                    <>
                                      {recipient?.transactionID ===
                                      proof?.transactionID ? (
                                        <div
                                          className="proof_transfer"
                                          key={index}
                                        >
                                          <a
                                            href={`${proof?.deposit?.url}`}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <BsFillFileEarmarkArrowDownFill />
                                            Preuve de transfert
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {removeTag && (
            <div className="success-upload">
              <p>successfully uploaded the proof of deposit!</p>
              <div className="cancel-icon" onClick={() => setRemoveTag(false)}>
                <GiCancel />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default React.memo(UserAccount);
