import React, { useState, useEffect } from "react";
import "./Review.css";
import Card from "./Card";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { client } from "../../db/sanity";
import { fetchReviews } from "../../utils/data";

// import required modules
import { Autoplay } from "swiper/modules";

const Review = () => {
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await client.fetch(fetchReviews).then((data) => {
        setReviews(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="review">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path
          fill="#ffffff"
          fill-opacity="1"
          d="M0,288L60,282.7C120,277,240,267,360,272C480,277,600,299,720,266.7C840,235,960,149,1080,133.3C1200,117,1320,171,1380,197.3L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"
        ></path>
      </svg>
      <div className="container">
        <div className="review-heading">
          {/* <h4>Avis clients</h4> */}
          <h3>Ce que nos clients disent</h3>
        </div>
        <div className="reviews">
          <Swiper
            spaceBetween={30}
            slidesPerView="auto"
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              760: {
                slidesPerView: 2,
              },
              1140: {
                slidesPerView: 3,
              },
              1500: {
                slidesPerView: 4,
              },
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay]}
          >
            {reviews?.map((review) => (
              <SwiperSlide key={review?._id}>
                <Card review={review} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Review;
