import React, { useState, useEffect } from "react";
import "./Auth.css";
import Title from "../../components/Title";
import { client } from "../../db/sanity";
import { fetchSteps } from "../../utils/data";

const LearnMore = () => {
  const [steps, setSteps] = useState([]);

  useEffect(() => {
    const fetchData = () => {
      client.fetch(fetchSteps).then((data) => {
        setSteps(data);
      });
    };
    fetchData();
  }, []);

  return (
    <div className="learn-more">
      {console.log(steps)}
      <div className="container">
        <div className="steps-heading">
          <Title
            title="Processus transactionnel"
            subtitle="Suivez les étapes suivantes pour envoyer de l'argent avec nous."
          />
        </div>
        <hr />
        <div className="steps container">
          {steps?.map((step) => (
            <div
              key={step?._id}
              className="steps_totake step layout-col-lg-md-4 layout-col-lg-4 layout-col-md-6 layout-col-sm-12"
            >
              <h1>{step?.number}</h1>
              <h4>{step?.title}</h4>
              <p>{step?.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(LearnMore);
