import React, { useState, useEffect } from "react";
import "./Auth.css";
import Title from "../../components/Title";
import Or from "../../assets/or.png";
import { FcGoogle } from "react-icons/fc";
import { NavLink } from "react-router-dom";
import { auth, googleProvider } from "../../db/firebase";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signInWithRedirect,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { AiOutlineEye } from "react-icons/ai";
import Loading from "../../components/Loading";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [isLogging, setIsLogging] = useState(false);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(false);
        navigate("/useraccount");
      } else {
        setLoading(false);
        navigate("/login");
      }
    });
  }, [navigate]);

  const HandleSubmit = async (e) => {
    e.preventDefault();
    setIsLogging(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        setErrorMessage(null);
        navigate("/useraccount", { userCredential: userCredential });
        setIsLogging(false);
      })
      .catch((error) => {
        const errorMessage = error.message;
        if (error.code === "auth/user-not-found") {
          setErrorMessage("L'utilisateur n'a pas été trouvé.");
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        } else if (error.code === "auth/wrong-password") {
          setErrorMessage("Le mot de passe est incorrect.");
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        } else {
          setErrorMessage(
            "Une erreur s'est produite lors de la connexion. Veuillez réessayer."
          );
          setTimeout(() => {
            setErrorMessage(null);
          }, 5000);
        }
        setIsLogging(false);
        console.log(errorMessage);
      });
  };

  const HandleGoogleSignUp = () => {
    signInWithRedirect(auth, googleProvider)
      .then((result) => {
        const user = result.user;
        navigate("/useraccount", { user });
      })
      .catch((error) => {
        if (error.code === "auth/account-exists-with-different-credential") {
          setErrorMessage(
            "Un compte existe déjà avec la même adresse e-mail mais des informations d'identification différentes"
          );
        } else {
          setErrorMessage(
            "Une erreur s'est produite lors de la connexion. Veuillez réessayer."
          );
        }
        console.log(errorMessage);
      });
  };

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="login">
          <div className="login-account container">
            <div className="login-details">
              <Title
                title="Connexion"
                // subtitle="To save your details and transactions login."
              />
              <div className="login-form">
                <form onSubmit={HandleSubmit}>
                  <input
                    type="email"
                    name="Email"
                    placeholder="Email Address"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="password_show">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div
                      className="eye_icon"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <AiOutlineEyeInvisible />
                      ) : (
                        <AiOutlineEye />
                      )}
                    </div>
                  </div>
                  {errorMessage && (
                    <p className="error_message">{errorMessage}</p>
                  )}
                  <button type="submit">
                    {isLogging ? "Se connecter..." : "Connexion"}
                  </button>
                </form>
                <p>
                  Vous n'avez pas de compte ?{" "}
                  <NavLink className="SignUp_link" to="/register">
                    S'inscrire
                  </NavLink>
                </p>
                <img src={Or} alt="or" />
                <p>Connectez-vous avec</p>
                <div className="social_login">
                  <div className="google" onClick={HandleGoogleSignUp}>
                    <p className="text_size">
                      <FcGoogle />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(Login);
